.moves {
  h3 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .move-items {
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}
