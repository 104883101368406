.decision-item {
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 30px 18px;
  border-radius: 50%;
  transition-duration: 500ms;
  transition-property: opacity;

  img {
    max-width: 50%;
    min-width: 50%;
    display: block;
    margin: 0 auto;
    height: auto;
    max-height: 100%;
  }
}
