.decisions {
  display: flex;
  justify-content: center;
  margin: 0 auto;

  .decision {
    min-width: 90px;

    h3 {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 30px;
    }

    &.middle {
      align-self: center;
      margin: 0 20px;

      &.multi {
        h3 {
          font-size: 1rem;
        }
      }

      h3 {
        margin-bottom: 10px;
      }
    }
  }
}

// .decisions-caption {
//   // background-color: var(--color-black);
//   // padding: 15px 10px;
//   // border-radius: 3px;
//   // width: 70%;
//   margin: 0 auto;
//   margin-top: 30px;
//   // color: var(--color-white);

//   h3 {
//     font-size: 1rem;
//   }
// }

@media only screen and (max-width: 325px) {
  .decisions {
    .decision {
      &.middle {
        margin: 0 15px;
      }
    }
  }
}
