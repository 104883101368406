.rules {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}
