.alert-container {
  position: relative;

  .alert {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 8;
    padding: 3px 0px;

    &.success {
      background-color: #2ab500;
    }

    &.failure {
      background-color: red;
    }

    &.regular {
      background-color: #0080de;
      // background-color: var(--color-black);
    }

    .alert-content {
      margin: auto;
      display: flex;
      justify-content: center;
      margin-top: 5px;

      .alert-message {
        // color: var(--color-white);
        color: white;
        font-weight: bold;
        text-align: center;
        padding-bottom: 5px;
      }
    }
  }
}
