.main-menu {
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 30px;
  }

  .custom-button {
    background-color: var(--color-black);
    color: var(--color-white);
    margin: 0 auto 10px;
    height: 50px;
    width: 60%;

    &:hover {
      opacity: 0.8;
      color: var(--color-white);
    }
  }
}
