.rule-item {
  background-color: #fff;
  margin: 19vh auto;
  padding: 25px 20px 15px;
  width: 350px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;

  .window-close {
    position: absolute;
    background-image: url('../../assets/window-close.png');
    background-repeat: no-repeat;
    background-size: 40%;
    width: 30px;
    height: 30px;
    right: 0%;
    top: 5%;
    cursor: pointer;
    transition-duration: 500ms;
    transition-property: opacity;

    &:hover {
      opacity: 0.6;
    }
  }

  h3 {
    color: black;
    font-weight: bold;
    font-size: 1rem;
  }

  .rule-item-linebreak {
    border-bottom: 2px solid black;
    margin: 5px auto 0;
    width: 3%;
  }

  .instructions {
    background-image: url('../../assets/rules-black.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    display: inline-block;
    width: 70%;
    height: 220px;
    margin: 0 auto;

    &:hover {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 650px) {
  .rule-item {
    width: 300px;

    h3 {
      font-size: 0.89rem;
    }

    // .window-close {
    //   top: 6%;
    // }

    .instructions {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 325px) {
  .rule-item {
    width: 250px;
    padding: 20px 20px 10px;
    width: 300px;

    h3 {
      font-size: 0.89rem;
    }

    .instructions {
      width: 90%;
    }
  }
}
