footer {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 900px;
  // min-height: 6vh;
  padding: 0 100px;
  margin: 0 auto;
  color: #bfbfbf;
  font-weight: 500;
  margin: 200px auto 20px;
}

@media only screen and (max-width: 600px) {
  footer {
    padding: 0 10%;
  }
}

@media only screen and (max-width: 430px) {
  footer {
    padding: 0 5%;
  }
}
