h3 {
  font-size: 1.3rem;
  font-weight: bold;
}

.main-container {
  text-align: center;
  max-width: 900px;
  padding: 0 100px;
  margin: 0 auto;
  min-height: 55vh;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .main-container {
    max-width: 1500px;
    width: 80%;
    padding: 0 10%;
  }
}

@media only screen and (max-width: 600px) {
  .main-container {
    padding: 0 10%;
  }
}

@media only screen and (max-width: 430px) {
  .main-container {
    padding: 0 5%;
  }
}
