.logo-container {
  line-height: 0.6875;
  display: flex;
  flex-direction: column;

  .logo {
    cursor: pointer;
    margin-bottom: 1.5px;
    align-self: flex-start;
    font-size: 2rem;
    font-weight: bold;
    transition-duration: 500ms;
    transition-property: opacity;

    &:hover {
      opacity: 0.7;
    }
  }
}
