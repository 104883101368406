.theme {
  margin-top: 20px;
  i {
    color: #bfbfbf;
    cursor: pointer;
    transition-duration: 500ms;
    transition-property: opacity, color;

    &:hover {
      opacity: 0.8;
      color: #0080de;
    }
  }
}
