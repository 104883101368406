.scissors-icon {
  background-image: var(--url-scissors);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  display: inline-block;
  width: 52px;
  height: 26px;
  margin: 0 auto;
}

.paper-icon {
  background-image: var(--url-paper);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  display: inline-block;
  width: 52px;
  height: 26px;
  margin: 0 auto;
}

.rock-icon {
  background-image: var(--url-rock);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  display: inline-block;
  width: 52px;
  height: 26px;
  margin: 0 auto;
}
