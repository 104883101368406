.custom-button {
  background-color: var(--color-white);
  padding: 0 30px;
  color: var(--color-black);
  height: 40px;
  font-family: 'Barlow Semi Condensed';
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.74rem;
  border-radius: 4px;
  border: var(--color-black) 2px solid;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition-duration: 500ms;
  transition-property: background-color, color, opacity;

  &.rulesBtn {
    background-color: transparent;
    padding: 0 80px;
    position: fixed;
    bottom: 2%;
    right: 1.5%;
  }

  &:hover {
    background-color: var(--color-black);
    color: var(--color-white);
  }
}

@media only screen and (max-width: 600px) {
  .custom-button {
    &.rulesBtn {
      padding: 0 50px;
    }
  }
}

@media only screen and (max-width: 380px) {
  .custom-button {
    font-size: 0.64rem;
    padding: 0 15%;
  }
}
