.score {
  padding: 15px 25px;
  border-radius: 10px;
  background-color: var(--color-black);
  color: var(--color-white);

  &.dummy {
    visibility: hidden;
  }

  .score-h2 {
    font-size: 0.78rem;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 2px;
  }

  .score-total {
    font-size: 1.79rem;
    font-weight: bold;
    margin-bottom: 5px;
  }

  span {
    display: block;
    font-size: 0.48rem;
  }

  .score-options {
    cursor: pointer;
    margin-bottom: 2px;
    transition-duration: 500ms;
    transition-property: opacity;

    &:hover {
      opacity: 0.7;
    }
  }
}
