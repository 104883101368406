.scores-item {
  padding: 15px 25px;
  color: var(--color-white);

  &.dummy {
    visibility: hidden;
  }

  .score-h2 {
    font-size: 0.78rem;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 2px;
    text-transform: uppercase;
  }

  .score-total {
    font-size: 1.79rem;
    font-weight: bold;
    margin-bottom: 5px;
  }

  span {
    display: block;
    font-size: 0.48rem;
  }

  .score-options {
    margin-bottom: 2px;
    transition-duration: 500ms;
    transition-property: opacity;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 400px) {
  .scores-item {
    padding: 15px 15px;
  }
}
