@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:400,800,900&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

:root {
  --bg: white;
  --score-bg: black;
  --color: #fff;
  --color-white: white;
  --color-black: black;
  --url-scissors: url('./assets/scissors-light.png');
  --url-paper: url('./assets/paper-light.png');
  --url-rock: url('./assets/rock-light.png');
}

[data-theme='dark'] {
  --bg: black;
  --score-bg: white;
  --color: black;
  --color-white: black;
  --color-black: white;
  --url-scissors: url('./assets/scissors-dark.png');
  --url-paper: url('./assets/paper-dark.png');
  --url-rock: url('./assets/rock-dark.png');
}

body {
  margin: 0;
  font-family: 'Barlow Semi Condensed', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 300ms;
  transition-property: background-color, color;
  background-color: var(--bg);
  color: var(--color-black);
}

input {
  font-family: 'Barlow Semi Condensed', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
